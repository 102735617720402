export const Services = () => {

	return (
		<section id="services" className="services-mf pt-5 route">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<div className="title-box text-center">
							<h2 className="title-a">Services</h2>
							<p className="subtitle-a">I help my clients with various technologies</p>
							<div className="line-mf"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-briefcase"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Web Design</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-card-checklist"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Web Development</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-bar-chart"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Photography</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-binoculars"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Responsive Design</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-brightness-high"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Graphic Design</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="service-box">
							<div className="service-ico">
								<span className="ico-circle"><i className="bi bi-calendar4-week"></i></span>
							</div>
							<div className="service-content">
								<h2 className="s-title">Marketing Services</h2>
								<p className="s-description text-center"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
